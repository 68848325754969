.react-month-picker {}
.react-month-picker .calendar-container {
  max-width: 360px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  background: #fff;
  border: 2px solid #eee;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

/*  SECTIONS  */
.react-month-picker .section_mp {
  clear: both;
  padding: 0px;
  margin: 0px;
}

/*  COLUMN SETUP  */
.react-month-picker .col_mp {
  display: block;
  float: left;
  text-align: center;
}
.react-month-picker .col_mp:first-child {
  margin-left: 0;
}

/*  GROUPING  */
.react-month-picker .group_mp:before, .react-month-picker .group:after {
  content: "";
  display: table;
}
.react-month-picker .group_mp:after {
  clear: both;
}
.react-month-picker .group_mp {
  zoom: 1; /* For IE 6/7 */
}

/*  GRID OF THREE  */
.react-month-picker .span_1_of_3_mp {
  width: 33.33%;
}
.react-month-picker .col_mp {
  font-size: 16px;
  padding-bottom: 5px;
  padding-top: 5px;
}
.react-month-picker .col_mp:hover {
  color: white;
  background-color: darkslateblue;
  cursor: pointer;
}
.react-month-picker .calendar-container.readonly .col_mp:hover {
  background-color: transparent;
  cursor: default;
  color: inherit;
}
.react-month-picker .selected_date_mp {
  font-size: 12px;
  color: darkslateblue;
  font-weight: bold;
}
.react-month-picker .selected_cell {
  background-color: darkslateblue;
  font-style: italic;
  color: #fff;
}
.react-month-picker .arrows_mp {
  font-weight: bold;
  font-size: 18px;
}
.react-month-picker .month-input.readonly {
  background-color: #eee;
}

@media only screen and (max-width: 360px) {
  .react-month-picker .col_mp {
    font-size: 14px;
  }
}
